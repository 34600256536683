import React from "react"
import { Box, Container, Heading } from "theme-ui"

const HeroPost = ( props ) => {

  const maintitle = props.maintitle
  const secondarytitle = props.secondarytitle
  const date = props.date

  return (
    <Box
      as={`div`}
      className={`main-title`}
      sx={{
        background: `linear-gradient(360deg, #fff, #d64a37)`,
        pt: 7,
        pb: [5, 6],
        position: `relative`,
        overflow: `hidden`,
        maxWidth: `100vw`,

        "> div": {
          height: `100%`,
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
        },
        h2: {
          fontSize: 0,
        },
        h1: {
          fontFamily: `body`,
          fontWeight: `bold`,
          lineHeight: `none`,
          my: 3,
          textTransform: `capitalize`,
        },
        ".date": {
          display: `block`,
          fontSize: 0,
        }
      }}
    >
      <Container as={`div`}>
        <Heading as={`h2`}>{secondarytitle}</Heading>
        <Heading as={`h1`}><span>{maintitle}</span></Heading>
        <span className="date">{date}</span>
      </Container>
    </Box>

  )

}

export default HeroPost
