import React from "react"
import Layout from "../components/layout"
import HeroPost from "../components/heropost"
import { graphql, Link } from "gatsby"
import { Box, Container, Themed } from "theme-ui"
import Seo from "../components/seo"

const Post = ({ data }) => {

  const postData = data.markdownRemark

  return (
    <Layout>
      <Seo
        title={postData.frontmatter.title}
        description={postData.excerpt}
      />
      <HeroPost
        secondarytitle='Insight'
        maintitle={postData.frontmatter.title}
        date={postData.frontmatter.date}
      />
      <Container as={`section`}
        sx={{
          ".content": {
            maxWidth: `728px`,
            overflow: `hidden`,
            my: [4, 5],
          },
          ".info": {
            fontSize: [2, 3, 3, 4, 4],
            lineHeight: `heading`,
            mb: [3, 4],
            p: {
              m: 0,
            }
          },
          ".link-to-insights": {
            display: `inline-block`,
            fontSize: 0,
            fontWeight: `bold`,
            my: [3, 4],
          }
        }}
      >
        <Box as={`div`} className="content">
          <Box
            sx={{
              variant: 'styles',
            }}
            dangerouslySetInnerHTML={{ __html: postData.html }}
          />
          <Themed.a as={Link} to="/insights" className="link-to-insights" aria-label="all insights">&#x02190;  All insights</Themed.a>
        </Box>
      </Container>
    </Layout>
  )

}

export default Post

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
      }
      excerpt
    }
  }
`
